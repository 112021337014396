import "styles/pages/return-info.scss"

import React from "react"
import { withPrefix } from "gatsby"

import Layout from "components/layout"
import Seo from "components/seo"
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs"
import PageHeader from "components/PageHeader/PageHeader"
import PageSubHeader from "components/PageSubHeader/PageSubHeader"

import IcoPDF from "assets/images/ico-pdf.svg"
import IcoAddress from "assets/images/ico-address.svg"
import IcoPhone from "assets/images/ico-phone.svg"
import IcoEnvelope from "assets/images/ico-envelope.svg"

const ReturnInfo = () => {
  const title = "Zwroty i reklamacje"

  return (
    <Layout>
      <Seo title={title} />
      <Breadcrumbs title={title} />

      <section className="return-info">
        <div className="container-fluid">
          <PageHeader title={title} />

          <div className="row">
            <div className="col-lg-4">
              <PageSubHeader title="Informacje ogólne" />
              <p>
                Prosimy zapoznać się z warunkami gwarancji znajdującymi się na
                odwrocie karty gwarancyjnej oraz na stronie memtech.pl
              </p>
              <p>
                Przed wysłaniem reklamowanego sprzętu prosimy o wypełnienie
                formularza reklamacyjnego i dołączenie go do przesyłki wraz z
                całością dokumentów wydawanych przy zakupie tj.: Kartą
                gwarancyjną i kopią dokumentu sprzedaży (paragon lub FV).
              </p>
              <p>
                Na rozpatrzenie reklamacji serwis ma 14 dni roboczych. 90%
                reklamacji staramy się obsługiwać w ciągu 2 dni roboczych od
                daty otrzymania produktu.
              </p>
              <p>
                Sprzęt powinien zostać zapakowany umożliwiając bezpieczny
                transport do serwisu. Dopuszczalne jest zastosowanie opakowania
                zastępczego, jednak musi ono gwarantować odpowiednią ochronę
                podczas transportu firmą spedycyjną.
              </p>
              <p>
                Firma MEMTECH nie ponosi odpowiedzialności za uszkodzenie
                przesyłek w transporcie.
              </p>
            </div>

            <div className="col-lg-3 offset-lg-1">
              <PageSubHeader title="Do pobrania" />
              <a
                className="return-info__link"
                href={withPrefix("/zgloszenie-reklamacyjne.pdf")}
                target="_blank"
                rel="noreferrer"
              >
                <img src={IcoPDF} alt="" />
                Formularz reklamacyjny
              </a>
              <a
                className="return-info__link"
                href={withPrefix("/formularz-odstapienia-od-umowy.pdf")}
                target="_blank"
                rel="noreferrer"
              >
                <img src={IcoPDF} alt="" />
                Formularz odstąpienia od Umowy
              </a>

              <PageSubHeader title="Adres Wysyłki" />
              <address className="return-info__link test">
                <img src={IcoAddress} alt="" />
                <span>
                  Memtech <br />
                  ul. Beskidzka 2a
                  <br />
                  34-322 Rychwałd
                </span>
              </address>
            </div>

            <div className="col-lg-3 offset-lg-1">
              <PageSubHeader title="Kontakt" />
              <p>W przypadku pytań i wątpliwości, prosimy o kontakt mailowy:</p>
              <a href="tel:+48511505050" className="return-info__link">
                <img src={IcoPhone} alt="" />
                511-505-050
              </a>
              <a href="mailto:serwis@memtech.pl" className="return-info__link">
                <img src={IcoEnvelope} alt="" />
                serwis@memtech.pl
              </a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ReturnInfo
